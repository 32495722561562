<template>
  <g>
    <svg:style>
      .cls-4, .cls-5, .cls-7 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-4, .cls-5 {
        stroke-linecap: square;
      }

      .cls-4 {
        fill: url(#inox-gradient);
      }

      .cls-5 {
        fill: url(#inox-gradient-2);
      }

      .cls-6 {
        fill: url(#glass-pattern);
      }

      .cls-7 {
        fill: url(#inox-gradient-3);
      }
    </svg:style>

    <linearGradient id="handle-gradient"
                    :x1="doorLeftWidth1 + 11.16"
                    :y1="doorTopHeight1 + 148.47"
                    :x2="doorLeftWidth1 + 16.75"
                    :y2="doorTopHeight1 + 148.47"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>

    <linearGradient id="inox-gradient"
                    :x1="doorLeftWidth1 + 386.15"
                    :y1="doorTopHeight1 + 7496.18"
                    :x2="doorLeftWidth1 + 386.15"
                    :y2="doorTopHeight1 + 7468.15"
                    xlink:href="#handle-gradient"/>

    <linearGradient id="inox-gradient-2"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth1 + 386.15"
                    :y1="doorTopHeight1 + 7454.28"
                    :x2="doorLeftWidth1 + 386.15"
                    :y2="doorTopHeight1 + 7426.24"
                    xlink:href="#handle-gradient"/>

    <linearGradient id="glass-gradient"
                    :x1="doorLeftWidth1 + 386.15"
                    :y1="doorTopHeight1 + 7407.24"
                    :x2="doorLeftWidth1 + 386.15"
                    :y2="doorTopHeight1 + 7301.66"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f4f4f4"/>
      <stop offset="0.5" stop-color="#dee8e8"/>
      <stop offset="1" stop-color="#d1cece"/>
    </linearGradient>

    <linearGradient id="inox-gradient-3"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth1 + 386.15"
                    :y1="doorTopHeight1 + 7412.17"
                    :x2="doorLeftWidth1 + 386.15"
                    :y2="doorTopHeight1 + 7296.54"
                    xlink:href="#handle-gradient"/>
    <g id="c03">
      <path id="inox"
            v-if="showInox"
            class="cls-4"
            :d="`
            M${doorLeftWidth1 + 351.26},${doorTopHeight1 + 7468.15}
            v28
            H${doorLeftWidth1 + 421}
            v-28
            H${doorLeftWidth1 + 351.26}
            Z
            m65,23.07
            H${doorLeftWidth1 + 356.08}
            V${doorTopHeight1 + 7473}
            h60.14
            v18.25
            Z
            `"
            transform="translate(-316.24 -7249.7)"
      />
      <path id="inox-2"
            v-if="showInox"
            data-name="inox"
            class="cls-5"
            :d="`
            M${doorLeftWidth1 + 351.26},${doorTopHeight1 + 7426.24}
            v28
            H${doorLeftWidth1 + 421}
            v-28
            H${doorLeftWidth1 + 351.26}
            Z
            m65,23.07
            H${doorLeftWidth1 + 356.08}
            v-18.25
            h60.14
            v18.25
            Z
            `"
            transform="translate(-316.24 -7249.7)"
      />
      <path id="glass"
            class="cls-6"
            :d="`
            M${doorLeftWidth1 + 416},${doorTopHeight1 + 7407.24}
            v-89
            c-13.3-22.62-47.14-22-59.65,1
            l0,0
            v87.9
            H${doorLeftWidth1 + 416}
            Z
            `"
            transform="translate(-316.24 -7249.7)"
      />

      <path id="inox-3"
            v-if="showInox"
            data-name="inox"
            class="cls-7"
            :d="`
            M${doorLeftWidth1 + 351},${doorTopHeight1 + 7318}
            v94.12
            h70.26
            l0-95.25
            h0
            C${doorLeftWidth1 + 406.48},${doorTopHeight1 + 7289.3},${doorLeftWidth1 + 365},${doorTopHeight1 + 7289.82},${doorLeftWidth1 + 351},${doorTopHeight1 + 7318}
            Z
            m65,89.19
            H${doorLeftWidth1 + 356.3}
            v-87.9
            l0,0
            c12.51-23.07,46.35-23.67,59.65-1
            v89
            Z
            `"
            transform="translate(-316.24 -7249.7)"
      />
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'doorGlassWidth',
    'doorGlassHeight',
    'showInox',
    'doorBackgroundColor',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 70
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 148
    },
  }
}
</script>
